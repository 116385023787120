import * as React from 'react';

import { Link } from 'gatsby';
import { Navbar, Header } from '../components';

// markup
const NotFoundPage = () => {
    return (
        <main>
            <title>Not found</title>
            <Navbar />
            <Header>Page not found</Header>
            <p>
                Sorry{' '}
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>{' '}
                we couldn’t find what you were looking for.
                <br />
                <br />
                <a href="/">Go home</a>.
            </p>
        </main>
    );
};

export default NotFoundPage;
